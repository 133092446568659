.Hero {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  color: #fff;
  .herobox {
    width: 45%;
    height: 30%;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .title {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      height: 50%;

      .block {
        width: 0%;
        height: inherit;
        background: #ffb510;
        position: absolute;
        animation: mainBlock 2s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
        display: flex;
      }

      h1 {
        font-family: Raleway, sans-serif;
        text-transform: uppercase;
        color: #fff;
        font-size: 4rem;
        -webkit-animation: mainFadeIn 2s forwards;
        -o-animation: mainFadeIn 2s forwards;
        animation: mainFadeIn 2s forwards;
        animation-delay: 1.6s;
        opacity: 0;
        display: flex;
        align-items: baseline;
        position: relative;
        font-weight: 300;
      }
    }

    .role {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      height: 50%;
      margin-top: -3rem;

      .block {
        width: 0%;
        height: inherit;
        background: #ffb510;
        position: absolute;
        animation: secBlock 2s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
        animation-delay: 2s;
        display: flex;
      }

      p {
        animation: secFadeIn 2s forwards;
        animation-delay: 3.2s;
        opacity: 0;
        font-weight: 400;
        font-family: Raleway, sans-serif;
        color: #ffffff;
        font-size: 1.5rem;
        text-transform: uppercase;
        letter-spacing: 0.4rem;
      }
    }
  }
}

@keyframes mainBlock {
  0% {
    width: 0%;
    left: 0;
  }
  50% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 0;
    left: 100%;
  }
}

@keyframes secBlock {
  0% {
    width: 0%;
    left: 0;
  }
  50% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 0;
    left: 100%;
  }
}

@keyframes mainFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes popIn {
  0% {
    width: 0rem;
    height: 0rem;
    background: #e9d856;
    border: 0rem solid #ddd;
    opacity: 0;
  }
  50% {
    width: 0.9rem;
    height: 0.9rem;
    background: #e9d856;
    opacity: 1;
    bottom: 3.5rem;
  }
  65% {
    width: 0.7rem;
    height: 0.7rem;
    bottom: 0rem;
    width: 1rem;
  }
  80% {
    width: 0.9rem;
    height: 0.9rem;
    bottom: 1.6rem;
  }
  100% {
    width: 0.7rem;
    height: 0.7rem;
    background: #e9d856;
    border: 0px solid #222;
    bottom: 1.45rem;
  }
}

@keyframes secFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}

@media (max-width: 768px) {
  .Hero {
    .herobox {
      width: 80%;
      height: 30%;
      .title {
        width: 100%;
        height: 50%;

        .block {
          width: 0%;
          height: inherit;
        }

        h1 {
          font-size: 3rem;
        }
      }
      .role {
        width: 100%;
        height: 50%;
        margin-top: -1.5rem;
        .block {
          width: 0%;
          height: inherit;
        }

        p {
          font-size: 1.1rem;
          letter-spacing: 0.2rem;
        }
      }
    }
  }
}

@media (min-width: 1180px) {
  .Hero {
    .herobox {
      width: auto;
      .title {
        height: 70%;
        h1 {
          font-size: 4rem;
        }
      }
      p {
        font-size: 1.6rem;
      }
    }
  }
}
