.fp-controlArrow.fp-next {
  border-width: 0 0.2rem 0.2rem 0 !important;
  border-color: #fff !important;
  padding: 0.5rem;
  transform: rotate(-45deg);
  &:hover {
    border-color: #ffb510;
    transition: 0.3s ease-in-out;
    padding: 0.4rem;
  }
}

.fp-controlArrow.fp-prev {
  border-width: 0 0.2rem 0.2rem 0 !important;
  border-color: #fff !important;
  padding: 0.5rem;
  transform: rotate(135deg);
  &:hover {
    border-color: #ffb510;
    transition: 0.3s ease-in-out;
    padding: 0.4rem;
  }
}

.Project {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 2rem 10%;
  font-size: 1rem;
  color: #fff;
  margin: 0 1rem 0;
  h3 {
    font-size: 2.2rem;
    font-family: Poppins, sans-serif;
    font-weight: 600;
    padding: 0 1rem;
  }
  .Project-desc {
    display: flex;
    align-items: center;
    padding: 1rem;
    div {
      display: flex;
      flex-wrap: wrap;
    }
    img {
      height: 25rem;
      width: auto;
      box-shadow: rgba(204, 204, 204, 0.24) 0px 4px 8px;
    }
    .Project-text {
      display: flex;
      flex-direction: column;
      margin-left: 2rem;
      div {
        a {
          margin: 1rem 1rem 0 0;
          display: inline-block;
          text-decoration: none;
          padding: 1rem;
          border: 1px solid #fff;
          color: #fff;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .fp-controlArrow.fp-next {
    border-width: 0 0.2rem 0.2rem 0;
    border-color: #fff;
    padding: 0.5rem;
    transform: rotate(-45deg);
    &:hover {
      border-color: #ffb510;
      transition: 0.3s ease-in-out;
      padding: 0.4rem;
    }
  }

  .fp-controlArrow.fp-prev {
    border-width: 0 0.2rem 0.2rem 0;
    border-color: #fff;
    padding: 0.5rem;
    transform: rotate(135deg);
    &:hover {
      border-color: #ffb510;
      transition: 0.3s ease-in-out;
      padding: 0.4rem;
    }
  }

  .Project {
    align-items: center;
    justify-content: center;
    margin: 0 0.1rem 0;
    padding: 0.2rem;
    font-size: 0.9rem;
    h3 {
      font-size: 1.5rem;
      padding: 0 0.5rem;
      text-align: center;
    }
    .Project-desc {
      flex-direction: column;
      align-items: center;
      padding: 1rem;
      div {
        // flex-direction: column;
      }
      img {
        height: 10rem;
      }
      .Project-text {
        display: flex;
        flex-direction: column;
        text-align: center;
        margin-left: 0;
        margin-top: 25px;
        div {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          a {
            margin: 0.5rem 0.5rem 0 0;
            display: inline-block;
            padding: 0.5rem;
          }
        }
      }
    }
  }
}
