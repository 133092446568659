.Contact {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0 1rem 0;
  padding: 2rem 10%;
  font-size: 1rem;
  color: #fff;
  h3 {
    font-size: 2.2rem;
    font-family: Poppins, sans-serif;
    font-weight: 600;
    padding: 0 1rem;
  }
  .Contact-desc {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    font-family: Raleway, sans-serif;
    font-weight: 500;
    p {
      width: 100%;
      text-align: center;
      font-size: 1.5rem;
    }
    a {
      img {
        display: inline-block;
        height: 5rem;
        width: auto;
      }
    }
  }
}

@media (max-width: 768px) {
  .Contact {
    justify-content: center;
    align-items: center;
    margin: 0 0.1rem 0;
    padding: 0.2rem;
    font-size: 0.9rem;
    h3 {
      font-size: 1.5rem;
      padding: 0 0.5rem;
    }
    .Contact-desc {
      padding: 1rem;
      p {
        font-size: 1rem;
      }
      a {
        margin: 1rem 0.3rem;
        padding: 0.3rem;
        img {
          height: 3rem;
        }
      }
    }
  }
}
