.About {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0 1rem 0;
  padding: 2rem 10%;
  font-size: 1rem;
  h3 {
    font-size: 2.2rem;
    font-family: Poppins, sans-serif;
    font-weight: 600;
    padding: 0 1rem;
  }
  .About-desc {
    display: flex;
    align-items: center;
    
    &_links {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 15px;
      padding: 1rem;
    }
    div {
      padding: 1rem;
      img {
        height: 20rem;
        display: block;
        width: auto;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        filter: grayscale(1);
        &:hover {
          filter: grayscale(0);
          transition: ease-in 0.3s;
          cursor: pointer;
        }
      }
      p {
        text-align: start;
      }
      a {
        color: #3e3e3e;
      }
      .link-btn {
        margin: 1rem 1rem 0 0;
        display: inline-block;
        padding: 1rem;
        border: 1px solid #3e3e3e;
        text-decoration: none;
      }
    }
  }
}

@media (max-width: 768px) {
  .About {
    margin: 0 0.1rem 0;
    padding: 0.2rem;
    font-size: 0.9rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h3 {
      font-size: 1.5rem;
      padding: 0 0.5rem;
    }
    .About-desc {
      display: flex;
      flex-direction: column;
      align-items: center;
      div {
        padding: 1rem;
        text-align: center;
        img {
          display: none;
        }
        p {
          font-size: 0.85rem;
        }
        // a {
        //   margin: 1rem 1rem 0 0;
        //   padding: 1rem;
        // }
      }
    }
  }
}
