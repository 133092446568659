.Stack {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0 1rem 0;
  padding: 2rem 10%;
  font-size: 1rem;
  color: #fff;
  h3 {
    font-size: 2.2rem;
    font-family: Poppins, sans-serif;
    font-weight: 600;
    padding: 0 1rem;
  }
  .Stack-desc {
    display: flex;
    align-items: center;
    padding: 1rem;
    .wr_right {
      display: flex;
      flex-wrap: wrap;
      flex-basis: 35%;
      align-items: center;
      gap: 10px;
      .tech {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 100px;
        min-width: 30%;
        background-color: #B2B2B2;
        & svg {
          display: block;
          height: 80px;
          width: auto;
        }
      }
    }
    .wr_left {
      flex-basis: 65%;
      justify-content: center;
      align-items: center;
      margin-right: 1rem;
      p {
        // text-align: justify;
        width: 80%;
      }
    }
  }
}

@media (max-width: 1030px) {
  .Stack .Stack-desc .wr_right {
    .tech {
      min-width: 46%;
      min-height: 60px;
      & svg {
        display: block;
        height: 50px;
        width: auto;
      }
    }
  }
}

@media (max-width: 768px) {
  .Stack {
    // Remove unnecessary styles
    margin: 0;
    padding: 0;
    font-size: 0.9rem;
    justify-content: center;
    align-items: center;

    h3 {
      font-size: 1.5rem;
      padding: 0 0.5rem;
    }

    .Stack-desc {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 1rem;
      gap: 10px;
      .wr_right {
        display: flex;
        flex-wrap: wrap;
        justify-content: center; 
        align-items: center; 
        flex-basis: 100%; 

        .tech {
          img {
            width: 2rem;
          }
        }
      }

      .wr_left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 0;
        padding: 0;

        p {
          text-align: justify;
          width: 100%;
          margin: 10px;
        }
      }
    }
  }
}
