@import url("https://fonts.googleapis.com/css?family=Raleway:400,500,600|Poppins:300,400,600,800&display=swap");

.section1,
.section2,
.section3,
.section4,
.section5 {
  color: #3e3e3e;
  font-size: 1rem;
}

.section1 {
  background-color: #2a9d8f;
}

.section2 {
  background-color: #ffb510;
}

.section3 {
  background-color: #545e75;
}

.section4 {
  background-color: #363457;
}

.section5 {
  background-color: #c03221;
}

// #fp-nav,
// .fp-right,
// #fp-nav .fp-right {
//   top: 90%;
// }

// #fp-nav ul li:hover a.active span,
// #fp-nav ul li a.active span,
// .fp-slidesNav ul li:hover a.active span,
// .fp-slidesNav ul li a.active span,
// #fp-nav ul li a span {
//   background-color: #fff;
// }
