body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.fp-controlArrow.fp-next {
  border-width: 0 0.2rem 0.2rem 0;
  border-color: #fff;
  padding: 0.5rem;
  transform: rotate(-45deg);
  &:hover {
    border-color: #ffb510;
    transition: 0.3s ease-in-out;
    padding: 0.4rem;
  }
}

.fp-controlArrow.fp-prev {
  border-width: 0 0.2rem 0.2rem 0;
  border-color: #fff;
  padding: 0.5rem;
  transform: rotate(135deg);
  &:hover {
    border-color: #ffb510;
    transition: 0.3s ease-in-out;
    padding: 0.4rem;
  }
}

@media (max-width: 768px) {
  #fp-nav{ display: none;}
}


#fp-nav,
.fp-right,
#fp-nav .fp-right {
  top: 90%;
}

#fp-nav ul li:hover a.active span,
#fp-nav ul li a.active span,
.fp-slidesNav ul li:hover a.active span,
.fp-slidesNav ul li a.active span,
#fp-nav ul li a span {
  background-color: #fff;
}
